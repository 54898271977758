import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5be74d74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overline" }
const _hoisted_2 = { class: "scanner" }
const _hoisted_3 = {
  key: 0,
  class: "segments"
}
const _hoisted_4 = {
  key: 1,
  class: "empty-state"
}
const _hoisted_5 = { class: "order-header" }
const _hoisted_6 = { class: "order-primary-info" }
const _hoisted_7 = { class: "order-tags" }
const _hoisted_8 = { class: "order-metadata" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "product-info" }
const _hoisted_11 = { class: "overline" }
const _hoisted_12 = { class: "product-metadata" }
const _hoisted_13 = { class: "actions" }
const _hoisted_14 = { class: "desktop-only" }
const _hoisted_15 = {
  key: 1,
  class: "empty-state"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_TransferOrderItem = _resolveComponent("TransferOrderItem")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                "default-href": "/transfer-orders",
                slot: "start"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Transfer Order Details")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createVNode(_component_ion_item, { lines: "none" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.currentOrder.orderId), 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.currentOrder.orderName) + " ", 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.currentOrder.externalId), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.translate('Item count')) + ": " + _toDisplayString(_ctx.getItemCount()), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_badge, { slot: "end" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.currentOrder.orderStatusDesc ? _ctx.currentOrder.orderStatusDesc : _ctx.getStatusDesc(_ctx.currentOrder.statusId)), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_input, {
                    label: _ctx.translate('Scan items'),
                    autofocus: "",
                    placeholder: _ctx.translate('Scan barcodes to pick them'),
                    modelValue: _ctx.queryString,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.queryString) = $event)),
                    onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.updateProductCount()), ["enter"]))
                  }, null, 8, ["label", "placeholder", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                expand: "block",
                fill: "outline",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.scanCode()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.barcodeOutline
                  }, null, 8, ["icon"]),
                  _createTextVNode(_toDisplayString(_ctx.translate("Scan")), 1)
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_ion_segment, {
              scrollable: "",
              modelValue: _ctx.selectedSegment,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedSegment) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_segment_button, { value: "open" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Open")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_segment_button, { value: "completed" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Completed")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"]),
            (_ctx.currentOrder)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_ctx.selectedSegment === 'open')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_ctx.getTOItems('open')?.length > 0)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.getTOItems('open'), (item) => {
                              return (_openBlock(), _createBlock(_component_TransferOrderItem, {
                                key: item.orderItemSeqId,
                                itemDetail: item,
                                class: _normalizeClass(item.internalName === _ctx.lastScannedId ? 'scanned-item' : '' ),
                                id: item.internalName
                              }, null, 8, ["itemDetail", "class", "id"]))
                            }), 128))
                          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.translate('No data available')), 1)
                            ]))
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (_ctx.getShipments('shipped')?.length > 0)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.getShipments('shipped'), (shipment, index) => {
                              return (_openBlock(), _createBlock(_component_ion_card, {
                                class: "order",
                                key: index
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_5, [
                                    _createElementVNode("div", _hoisted_6, [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(_ctx.translate("Shipped")) + " " + _toDisplayString(_ctx.getTime(shipment.statusDate)), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _createElementVNode("div", _hoisted_7, [
                                      _createVNode(_component_ion_chip, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_icon, { icon: _ctx.pricetagOutline }, null, 8, ["icon"]),
                                          _createVNode(_component_ion_label, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(shipment.shipmentId), 1)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _createElementVNode("div", _hoisted_8, [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.getShipmentMethodDesc(shipment.shipmentMethodTypeId)) + " ", 1),
                                          (shipment.trackingIdNumber)
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.translate("Tracking Code")) + " " + _toDisplayString(shipment.trackingIdNumber), 1))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ])
                                  ]),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(shipment.items, (item) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: item.shipmentItemSeqId,
                                      class: "order-item"
                                    }, [
                                      _createElementVNode("div", _hoisted_10, [
                                        _createVNode(_component_ion_item, { lines: "none" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_DxpShopifyImg, {
                                                  src: _ctx.getProduct(item.productId).mainImageUrl,
                                                  size: "small"
                                                }, null, 8, ["src"])
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createVNode(_component_ion_label, null, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1),
                                                _createTextVNode(" " + _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : _ctx.getProduct(item.productId).productName) + " ", 1),
                                                _createElementVNode("p", null, _toDisplayString(_ctx.getFeature(_ctx.getProduct(item.productId).featureHierarchy, '1/COLOR/')) + " " + _toDisplayString(_ctx.getFeature(_ctx.getProduct(item.productId).featureHierarchy, '1/SIZE/')), 1)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _createElementVNode("div", _hoisted_12, [
                                        _createVNode(_component_ion_item, { lines: "none" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_badge, {
                                              color: "medium",
                                              slot: "end"
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(item.orderedQuantity) + " " + _toDisplayString(_ctx.translate("ordered")), 1)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createVNode(_component_ion_badge, {
                                              color: "success",
                                              class: "ion-margin-start",
                                              slot: "end"
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(item.quantity) + " " + _toDisplayString(_ctx.translate("shipped")), 1)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ])
                                    ]))
                                  }), 128)),
                                  _createElementVNode("div", _hoisted_13, [
                                    _createElementVNode("div", _hoisted_14, [
                                      _createVNode(_component_ion_button, {
                                        fill: "outline",
                                        onClick: _withModifiers(($event: any) => (_ctx.regenerateShippingLabel(shipment)), ["stop"])
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.translate("Regenerate Shipping Label")) + " ", 1),
                                          (shipment.isGeneratingShippingLabel)
                                            ? (_openBlock(), _createBlock(_component_ion_spinner, {
                                                key: 0,
                                                color: "primary",
                                                slot: "start",
                                                name: "crescent"
                                              }))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"]),
                                      (!shipment.trackingIdNumber)
                                        ? (_openBlock(), _createBlock(_component_ion_button, {
                                            key: 0,
                                            fill: "outline",
                                            onClick: _withModifiers(($event: any) => (_ctx.showShippingLabelErrorModal(shipment)), ["stop"])
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.translate("Shipping label error")), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"]))
                                        : _createCommentVNode("", true)
                                    ])
                                  ])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.translate('No data available')), 1)
                            ]))
                      ], 64))
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      (_ctx.currentOrder.statusId === 'ORDER_APPROVED')
        ? (_openBlock(), _createBlock(_component_ion_footer, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        color: "primary",
                        fill: "solid",
                        disabled: !_ctx.hasPermission(_ctx.Actions.APP_TRANSFER_ORDER_UPDATE) || !_ctx.isEligibleForCreatingShipment(),
                        onClick: _ctx.confirmCreateShipment
                      }, {
                        default: _withCtx(() => [
                          (_ctx.isCreatingShipment)
                            ? (_openBlock(), _createBlock(_component_ion_spinner, {
                                key: 0,
                                slot: "start",
                                name: "crescent"
                              }))
                            : _createCommentVNode("", true),
                          _createTextVNode(" " + _toDisplayString(_ctx.translate('Create shipment')), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}